<template>
  <div>
    <header class="flex header">
      <nav class="flex custom-container justify-between py-5">
        <div class="flex">
          <router-link
            class="btn w-56 -ml-12"
            style="color: #274FED;"
            :to="{ name: 'login' }"
          >
            <img :src="logo" alt="CredPal" class="" />
          </router-link>
        </div>
        <div
          class="flex w-full md:w-3/10 justify-end md:justify-between md:mr-12 md:mt-4"
        >
          <template v-if="!checkAuth">
            <div
              class="font-normal text-blue-500 cursor-pointer hidden md:block md:mr-4"
            >
              <router-link class="" :to="{ name: 'login' }">
                Sign In</router-link
              >
            </div>
            <div class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0">
              <router-link class="" :to="{ name: 'signup' }"
                >Get Started</router-link
              >
            </div>
          </template>
          <div
            v-else
            class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0 ml-auto"
          >
            <router-link class="" :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </div>
        </div>
      </nav>
    </header>
    <section class="section" style="padding-bottom: 50px;">
      <div class="custom-container">
        <div class="md:w-6/10 mx-auto">
          <h1 class="mb-6 text-4xl md:text-5xl font-black leading-tight">
            Code of Conduct
          </h1>
        </div>
        <div class="md:w-6/10 mx-auto space-y-8">
          <div class="space-y-6">
            <h2 class="font-bold text-xl">1. Introduction</h2>
            <p>1.1. Welcome to Crednet Technologies Limited (CredPal).</p>
            <p>CredPal, established in 2017 by Fehintolu Olaogun and Olorunfemi Jegede, is dedicated to providing savings/investments and consumer credits to encourage wealth building and enhance the standard of living in Africa. Our core values include innovation, hard work, customer centrism, and dynamism.</p>
            <p>1.2 Mission: CredPal's mission is to enhance the quality of life by providing accessible funds growth services and easy access to consumer credit across all payment channels.</p>
            <p>1.3 Vision: CredPal aims to become the largest wealth savings and consumer credit brand out of Africa.</p>
            <p>1.4 Core Values: At CredPal, we have 6 core values that every member runs with. These values define our work culture and what we stand for as an organization.</p>
            <ul class="m-4 list-disc space-y-4">
              <li>Customer First: Our desire to always place people at the center of what we do runs deep. This motivates us to build solutions that align with the needs of today’s consumers.</li>
              <li>Ambitious: We prioritize persistent strive for successful attainment and accomplishment. This helps us to set and achieve goals as a team.</li>
              <li>Ownership: Taking ownership of duties gives each member a sense of leadership to ensure that projects are executed successfully.</li>
              <li>Teamwork: Collaboration within an organization helps solve difficult problems. We understand that it takes more than one man to build a successful organization. This helps us create a very strong team spirit which leads to exceptional innovation.</li>
              <li>Live Passionately: We value a work-life balance and ensure that every member of the team is living their best life while achieving the company’s goals.</li>
              <li>Open Communication: Effective communication improves the ability to collaborate with one another. We ensure that everyone uses the right words, the right tone, and the right channel.</li>
            </ul>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">2. You are part of the brand</h2>
            <p>As an affiliate of CredPal, you play a critical role in representing our brand and helping us reach potential investors/customers. We expect all affiliates to uphold the highest standards of integrity, transparency, and professionalism. This Code of Conduct outlines the ethical and professional standards required of our affiliates.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">3. Compliance with Laws and Regulation</h2>
            <p>Affiliates must comply with all applicable laws, regulations, and industry standards in the jurisdictions where they operate. This includes, but is not limited to, the Money Laundering (Prevention and Prohibition ) Act 2022, Securities and Exchange Commission Rules 2013, and amendments thereof. Central Bank of Nigeria Customer Due Diligence Regulations 2023, Nigerian Data Protection Act 2024.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">4. Honest and Transparent Communication</h2>
            <p>Affiliates must provide accurate and truthful information about CredPa's products and services. Misleading, deceptive, or false statements are strictly prohibited. All marketing materials and communications should clearly represent the nature of the investment products and associated risks.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">5. Confidentiality</h2>
            <p>Affiliates must maintain the confidentiality of all non-public information provided by CredPal and its clients. This includes but is not limited to, business strategies, financial data, customer information, and proprietary technologies. Confidential information should only be used for the purpose of fulfilling the affiliate's duties and should not be disclosed to any unauthorized third parties.</p>
          </div>
          <div class="space-y-6">        
            <h2 class="font-bold text-xl">6. Avoid Conflicts of Interest</h2>
            <p>Affiliates should avoid any activities or relationships that could create a conflict of interest or appear to do so. If a potential conflict arises, affiliates must disclose it to CredPal immediately and seek guidance on how to proceed.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">7. Professional Behavior</h2>
            <p>Affiliates are expected to conduct themselves in a professional manner at all times. This includes treating all clients, colleagues, and third parties with respect and courtesy. Affiliates should not engage in any conduct that could harm the reputation of CredPal.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">8. Protecting Client Interests</h2>
              <p>Affiliates must prioritize the interests of clients and act in their best interests at all times. This includes providing clear, accurate information about investment products and ensuring that recommendations are suitable for the client's financial situation and goals.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">9. Prohibition on Misuse and Recycling</h2>
              <p>Affiliates are strictly prohibited from engaging in any form of money laundering, funds layering, or recycling. All transactions must be conducted in compliance with AML regulations, and any suspicious activities must be reported to CredPal immediately.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">10. Record Keeping and Reporting</h2>
              <p>Affiliates must maintain accurate records of all transactions and communications related to their affiliate activities. These records should be made available to CredPal upon request for auditing and compliance purposes.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">11. Use of Marketing Materials</h2>
              <p>All marketing materials used by affiliates must be approved by CredPal before use. Affiliates should not create or distribute any materials that have not been reviewed and authorized by the company.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">12. Training and Development</h2>
              <p>Affiliates are required to participate in ongoing training programs provided by CredPal to stay informed about the latest products, services, and regulatory requirements. Continuous professional development is essential to maintaining high standards of knowledge and competence.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">13. Non-Discrimination</h2>
              <p>Affiliates must not engage in any form of discrimination based on race, color, religion, gender, age, national origin, disability, sexual orientation, or any other characteristic protected by law. All clients and colleagues should be treated with fairness and respect.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">14. Termination of Affiliation</h2>
              <p>Failure to comply with this Code of Conduct may result in the termination of the affiliate agreement. CredPal reserves the right to terminate any affiliate relationship at its discretion, particularly in cases of misconduct or non-compliance.</p>
          </div>
          
          <div class="space-y-6">
              <h2 class="font-bold text-xl">ACKNOWLEDGMENT</h2>
              <p>By signing this document, I acknowledge that I have read, understood, and agree to abide by the CredPal Code of Conduct. I also agree to participate in any required training and to comply with all applicable policies and procedures.</p>
              <p><strong>Affiliate Name:</strong></p>
              <p><strong>Signature:</strong></p>
              <p><strong>Date:</strong></p>
              <p><strong>This Code of Conduct helps ensure that all affiliates of CredPal operate with integrity and professionalism, fostering trust and credibility with clients and the broader financial community.</strong></p>
          </div>
        </div>
      </div>
    </section>

    <foot />
  </div>
</template>

<script>
export default {
  name: "Terms",
  components: {
    foot: require("./footer/Index").default,
  },
  data() {
    return {
      logo: require("@/assets/logo-blue.svg"),
    };
  },
  computed: {
    checkAuth() {
      return this.$store.state.session.loggedIn;
    },
  },
};
</script>

<style scoped>
header {
  background-color: #f3f5fc;
}
</style>
