<template>
  <div>
    <header class="flex header">
      <nav class="flex custom-container justify-between py-5">
        <div class="flex">
          <router-link
            class="btn w-56 -ml-12"
            style="color: #274FED;"
            :to="{ name: 'login' }"
          >
            <img :src="logo" alt="CredPal" class="" />
          </router-link>
        </div>
        <div
          class="flex w-full md:w-3/10 justify-end md:justify-between md:mr-12 md:mt-4"
        >
          <template v-if="!checkAuth">
            <div
              class="font-normal text-blue-500 cursor-pointer hidden md:block md:mr-4"
            >
              <router-link class="" :to="{ name: 'login' }">
                Sign In</router-link
              >
            </div>
            <div class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0">
              <router-link class="" :to="{ name: 'signup' }"
                >Get Started</router-link
              >
            </div>
          </template>
          <div
            v-else
            class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0 ml-auto"
          >
            <router-link class="" :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </div>
        </div>
      </nav>
    </header>
    <section class="section" style="padding-bottom: 50px;">
      <div class="custom-container">
        <div class="md:w-6/10 mx-auto">
          <h1 class="mb-6 text-4xl md:text-5xl font-black leading-tight">
            TERMS & CONDITIONS
          </h1>
        </div>
        <div class="md:w-6/10 mx-auto space-y-8">
          <p><b>THIS AGREEMENT</b> ("Agreement") is made and entered into as of ……………. Day of ………… 2024 (“Effective Date”)</p>
          <p class="font-bold">BETWEEN</p>
          <p><b>CREDNET TECHNOLOGIES LIMITED</b>, a company incorporated under the laws of the Federal Republic of Nigeria with its registered address at 75 Olonode Street Off Spencer Street Alagomeji Yaba Lagos (hereinafter referred to as the “<b>CREDPAL</b>” which expression shall where the context so admits include its successors-in-title and assigns) of the other part;</p>
          <p class="font-bold">AND</p>
          <p><b>[YOUR NAME]-----------------------------------------------------------------------</b> an individual/entity with its principal place of business at <b>[YOUR ADDRESS]-----------------------------------------------------------------------------------------------------------------------------</b>("<b>AFFILIATE</b>").</p>
          <p>“<b>CREDPAL</b>” and “<b>AFFILIATE</b>” are collectively referred to herein as the “Parties” and “Party” refers to either one of them.</p>
          <p>
            <b class="font-bold">WHEREAS</b>
            <ol class="list-decimal m-8 space-y-4" type="1">
              <li>CREDPAL is a financial technology company specializing in innovative investment and credit solutions including Buy Now Pay Later (BNPL) co-branded credit cards and tailored investment products. CredPal leverages technology to empower customers with accessible and flexible credit solutions and investment opportunities.</li>
              <li>The Affiliate possesses diverse marketing skills needed to effectively market CredPal’s products and services, build and engage potential customers of CredPal while driving traffic and sales through their marketing efforts.</li>
              <li>CredPal desires to engage the Wealth Partner to utilize the Affiliate’s marketing skills for the purpose of marketing CredPal’s investment and wealth management products and services as described in Recital above and overall contributing to CredPal’s revenue growth.</li>
            </ol>
          </p>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">1. Definitions</h2>
            <p>1.1. "Affiliate" refers to the individual or entity that promotes CredPal’s products and services under the terms of this Agreement.</p>
            <p>1.2. "Customer" refers to any individual or entity that is referred by the Affiliate and successfully engages in a financial product or service offered by CredPal.</p>
            <p>1.3. "Products and Services" refer to the credit and investment and wealth management products offered by CredPal.</p>
            <p>1.4. "Commission" refers to the payment made to the Affiliate for each successful referral that results in a completed transaction.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">2. Affiliate Responsibilities</h2>
            <p>2.1. Promotion: The Affiliate agrees to promote CredPal's Products and Services to potential customers through lawful and ethical means.</p>
            <p>2.2. Funds: The Affiliate agrees not to directly receive funds/ investments made by customers on-behalf of CredPal and to pay all such funds/investments into a verified account provided by CredPal.</p>
            <p>2.3. Compliance: The Affiliate must comply with all applicable laws regulations and guidelines including but not limited to Central Bank of Nigeria financial regulations, Anti-spam laws, Nigerian Data Protection Act 2020, Money Laundering (Prevention and Prohibition) Act 2022 and relevant rules and regulations issued by relevant regulatory bodies from time to time.</p>
            <p>2.4. Marketing Materials: CredPal will provide marketing materials which the Affiliate may use to promote the Products and Services. The Affiliate may not alter these materials without prior written consent from CredPal.</p>
            <p>2.5. Prohibited Actions: The Affiliate shall not engage in any misleading or deceptive advertising practices. The Affiliate shall not make any representations or warranties regarding CredPal’s Products and Services that are not expressly authorized by CredPal.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">3. CredPal's Responsibilities</h2>
            <p>3.1. Support: CredPal will provide reasonable support to the Affiliate including access to promotional materials and training if necessary.</p>
            <p>3.2. Tracking and Reporting: CredPal will track referrals and provide the Affiliate with reports on the performance of their referrals.</p>
            <p>3.3. Payment of Commissions: CredPal agrees to pay Commissions to the Affiliate in accordance with the terms set forth in this Agreement.</p>
            <p>3.4. Review of Promotional Materials: CredPal shall review the promotional materials/ communications used by the Affiliate to advertise CredPal’s products and services to ensure that it is compliant, consistent, and fit for purpose.</p>
            <p>3.5. Recommendation Letter/ ID Documents: CredPal shall provide the Affiliate with a Recommendation Letter and any other identification document affirming that the Affiliate is an agent of CredPal for the purpose of this Agreement only with a renewable validity period to be determined by CredPal from time to time.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">4. Commission and Payment</h2>
            <p>4.1. Commission Structure: The Affiliate will receive a Commission of [2-4]% of the revenue generated from each Customer calculated according to the amount deposited by each customer referred by the Affiliate who completes a transaction with CredPal PROVIDED THAT such customer has been fully on-boarded on CredPal App.</p>
            <p>4.2. Payment Terms: Commissions will be paid 30 days after investments are made by a downline provided that the Commission exceeds the minimum threshold of [N5000]. If the Commission does not exceed this threshold, it will be rolled over to the next payment period.</p>
            <p>4.3. Refunds and Chargebacks: If a transaction is refunded or results in a chargeback, the Commission for that transaction will be deducted from future payments to the Affiliate.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">5. Term and Termination</h2>
            <p>5.1. Term: This Agreement will commence on the Effective Date and will continue until terminated by either party.</p>
            <p>5.2. Termination for Convenience: Either party may terminate this Agreement at any time with or without cause by providing [30] days' written notice to the other party.</p>
            <p>5.3. Termination for Cause: CredPal may terminate this Agreement immediately if the Affiliate breaches any material term of this Agreement.</p>
            <p>5.4. Effect of Termination: <br /><br /><span class="ml-8">Upon the date of termination of this Agreement for whatever reason:</span></p>
            <ol class="ml-16 space-y-4" style="list-style-type: lower-alpha;">
              <li>All rights and authorizations granted under this Agreement shall rescind immediately.</li>
              <li>The Affiliate shall cease all promotion of CredPal's Products and Services.</li>
              <li>Any payments due to CredPal or the Affiliate under this Agreement shall become due and payable subject to the reconciliation of accounts between the Parties.</li>
              <li>All Confidential Information disclosed by CredPal in connection with the Service shall be returned to CredPal within 7 days and the Affiliate shall provide a sworn declaration on oath verifying that the same has been complied with.</li>
            </ol>
          </div>
          <div class="space-y-6">        
            <h2 class="font-bold text-xl">6. Confidentiality Non-Disclosure & Non-compete</h2>
            <p>6.1. The Affiliate hereby acknowledges that it will in the course of performing its obligations under this Agreement receive, obtain, or develop information that is confidential to CredPal. Confidential information shall for purposes of this Agreement include but not limited to any information relating to CredPal and/or a Customer, information on CredPal’s business strategy; business objectives; action plans; information regarding CredPal’s customers; financial performance and results; as well as any other information received or obtained by the Affiliate in the course of this Agreement, all information received by the Affiliate in connection with the installation, use, operation, and maintenance of any system or materials provided by CredPal in connection with the CredPal Service;</p>
            <p>6.2. The Affiliate hereby agrees to keep confidential such confidential information and in addition agrees and undertakes that during the continuance of this Agreement and thereafter (until any such confidential information falls into the public domain through no default or breach of this Agreement directly or indirectly by the Affiliate) it shall not without prior written consent of CredPal disclose to any person in any manner such confidential information.</p>
            <p>6.3. These confidentiality obligations shall survive the termination of this Agreement.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">7. Anti-Money Laundering (AML) and Prohibition of Funds Layering and Recycling</h2>
            <h3 class="font-semibold text-lg">7.1. Prohibition on Funds Layering and Recycling</h3>
            <p>The Affiliate acknowledges and agrees that it shall comply with all applicable anti-money laundering (AML) laws and regulations. Specifically, the Affiliate shall not engage in, assist, or otherwise facilitate any form of money laundering, including but not limited to, funds layering and recycling. Funds layering and recycling involve the process of disguising the origins of illegally obtained money through a complex sequence of banking transfers or commercial transactions.</p>
            <h2>7.2. To ensure compliance, the Affiliate shall adhere to the following obligations:</h2>
            <ol class="list-decimal space-y-6">
              <li><b>Know Your Customer (KYC) Procedures</b>: The Affiliate must facilitate/partner with CredPal to implement robust KYC procedures to verify the identity of all clients and investors. This includes collecting and maintaining accurate and current information regarding the identity, financial background, and source of funds of all clients.</li>
              <li><b>Prohibited Activities</b>: The Affiliate shall not:
                <ol class="space-y-4 ml-8"  style="list-style-type: lower-alpha;">
                  <li>Engage in any transactions that are designed to disguise the true origin of funds.</li>
                  <li>Structure transactions in a way that evades reporting requirements.</li>
                  <li>Facilitate the transfer of funds through multiple accounts or intermediaries to obscure the source or destination of the funds.</li>
                  <li>Accept or process transactions involving funds known or suspected to be derived from illegal activities.</li>
                </ol>
              </li>
              <li><b>Reporting Obligations</b>: The Affiliate must immediately report any suspicious activities or transactions that may indicate money laundering to CredPal's designated compliance officer. The Affiliate must cooperate fully with any investigations or audits conducted by CredPal or regulatory authorities regarding such activities.</li>
              <li><b>Training and Awareness</b>: The Affiliate is required to participate in regular AML training sessions provided by CredPal to ensure awareness and understanding of AML laws, regulations, and Company policies.</li>
              <li><b>Record Keeping</b>: The Affiliate must maintain comprehensive records of all transactions and due diligence efforts for a minimum period of five years or as required by law. These records must be readily available for review by CredPal and regulatory authorities.</li>
              <li><b>Compliance and Audits</b>: CredPal reserves the right to conduct regular audits of the Affiliate's operations to ensure compliance with this clause and applicable AML laws. The Affiliate must cooperate fully with any such audits and provide all requested documentation and information.</li>
            </ol>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">8. Intellectual Property</h2>
            <p>8.1. All Intellectual Property rights existing or belonging to either Party prior to this Agreement and intended to be used for the provision of the Service shall remain the sole property of that Party, and the Parties agree not to question nor dispute the ownership of such rights at any time.</p>
            <p>8.2. PROVIDED THAT all Intellectual Property rights belonging to CredPal and shared with the Affiliate  and all Intellectual Property developed by the Affiliate during the term of this Agreement for the purpose of achieving the Affiliate obligations described in the Agreement shall remain the sole property of CredPal, and the Affiliate shall not question nor dispute the ownership of such rights at any time.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">9. Force Majeure</h2>
            <p>9.1.	If the performance of this Agreement or any obligation under it is prevented, restricted or interfered with by reason of a Force Majeure event, the Party so affected shall promptly notify the other Parties in writing of the existence of the Force Majeure event, the anticipated length of delay, the cause of the delay and a timetable by which any remedial measures will be implemented.</p>
            <p>9.2.	If the Force Majeure event continues for a period less than thirty (30) days, then during that period, the Agreement shall be considered suspended. Upon the end of the Force Majeure event, the contractual obligations of the Parties shall be reinstated with such reasonable modifications to take account of the consequences of the Force Majeure event as may be agreed by the Parties, or in default of such agreement, as may be determined by an expert or court of competent jurisdiction. </p>
            <p>9.3.	If the Force Majeure event continues beyond thirty (30) days, then this Agreement may be terminated by mutual consent and neither Party shall be liable to the other Party as a result of such termination.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">10. Notices</h2>
              <p>10.1.	Any notice or other written communication required or authorized to be given by a Party under this Agreement to other Parties shall be in writing and may be sent by e-mail, facsimile, hand delivery or registered airmail or pre-paid air freight to the other Party and marked for the attention of the person set out below or such address, number electronic mail address or person as that Party may notify the other from time to time in accordance with this Clause.</p>
              <div>
                For CREDPAL
                <br>
                Address: 75, Olonode Street, Alagomeji, Yaba, Lagos
                <br>
                Email: affiliate@credpal.com
                <br>
                Phone No: 08143110361
                <br>
                Signature: Crednet Technologies	 
                <br>
                For AFFILIATE
                <br>
                Address: —-----------------------------------------------------------------------------------------------------------
                <br>
                Email: —-------------------------------------------------------------------------------------
                <br>
                Phone No:-----------------------------------------------
                <br>
                Attention: —------------------------------------------------
                <br>
                or such other address, fax number, or email address duly notified to the other Party in writing.
              </div>
            <p>10.2.	Any notice given following this clause shall be deemed to have been duly given upon delivery if hand delivered and duly acknowledged by an officer or employee of the recipient, or if by courier Service upon confirmation of delivery, or due confirmation of transmission by the recipient if sent by electronic mail.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">11. Governing Law and Dispute Resolution</h2>
            <p>11.1. This Agreement shall be governed by and construed by the Laws of the Federal Republic of Nigeria.</p>
            <p>11.2. If any dispute or difference arises as to the validity, interpretation, effect or rights and obligations of the Parties under this Agreement, the Parties shall use their best endeavour to reach an amicable settlement of the dispute.</p>
            <p>11.3. If any dispute is not settled by mediation within thirty (30) days, the Parties agree that the dispute shall be referred to arbitration by the provisions of the Arbitration and Mediation Act 2023. The Arbitration shall be conducted by a sole Arbitrator who shall be appointed by the mutual agreement of the Parties and in the absence of an agreement, within ten (10) days from the date of notice of Arbitration filed by either Party, by the President of the Chartered Institute of Arbitrators UK (Nigeria Branch) on the application of either Party.</p>
            <p>11.4. The Arbitration shall be conducted in Lagos, Nigeria and the language of the Arbitration shall be English.</p>
            <p>11.5. The decision of the Arbitrator shall be binding on the Parties and shall include an order as to the costs of such arbitration and which Party shall pay such costs.</p>
            <p>11.6. Each Party shall bear the cost of its legal representation in the arbitration proceeding.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">12. Severability</h2>
            <p>It is agreed and understood that if any provision of this Agreement becomes illegal, invalid, or unenforceable in any respect, the legality, validity, and enforceability of the other provisions of this Agreement shall not in any way be affected or impaired, and this Agreement shall be construed as if such illegal, invalid or unenforceable provision were not contained herein and in such event,  the Parties shall endeavor to carry out the terms of this Agreement as nearly as possible following its original terms and intent.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">13. Amendment</h2>
            <p>This Agreement may only be amended in writing and signed by the Parties or their duly authorized representatives.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">14. Data Protection</h2>
            <p>14.1.	The Parties herein agree to strictly comply with all applicable privacy and data protection laws and implementation frameworks (including but not limited to Nigeria Data Protection Regulation) in force in the Federal Republic of Nigeria in the processing of personal data and other relevant data obtained in the course of the performance of their respective obligations under this agreement. Where any of the Parties utilizes third-party processors to perform its obligations under this Agreement, the Party shall ensure that the processor is under a similar confidentiality and data protection obligation as those contained in this Agreement. Each Party shall be liable for its unlawful processing of such data and that of its third-party processors.</p>
            <p>14.2.	The Parties shall ensure that they adopt and implement adequate physical and technical measures to guarantee the confidentiality, secure processing, and storage of personal and sensitive data obtained and processed under their respective obligations under this Agreement.</p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">15. Indemnity</h2>
            <p>The Parties hereby agree to indemnify and hold each other harmless from and against any direct losses, claims, damages or liabilities (or actions in respect thereof) out of which a Party may become subject as a result of any grossly negligent act or wilful misconduct on the part of the other Party, in the performance of its obligations under this Agreement. </p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">16. Limitation of Liability</h2>
            <p>16.1 Neither Party shall be liable to the other Party in contract, tort, breach of statutory duty, strict liability, or otherwise for any loss, damage, costs or expenses of any nature whatsoever incurred or suffered by that other Party of an indirect or consequential nature, including without limitation to any economic loss or other loss of turnover, profits, business or goodwill.</p>
            <p>16.2. The limitations and exclusions of liability do not apply to:
              <ol class="space-y-4 ml-8"  style="list-style-type: lower-alpha;">
                <li>damages and costs attributable to a breach of CredPal’s confidentiality obligations;</li>
                <li>the infringement or misappropriation by the Affiliate of CredPal’’s Intellectual Property;</li>
                <li>damages attributable to a Party’s violation of applicable law;</li>
                <li>a Party’s or its representative’s fraud, fraudulent misrepresentation, gross negligence, or wilful misconduct; or</li>
                <li>the extent that any applicable law precludes or prohibits any exclusion or limitation of liability.</li>
              </ol>
            </p>
          </div>
          <div class="space-y-6">
            <h2 class="font-bold text-xl">17. Assignment</h2>
            <p>The Affiliate may not assign this Agreement, in whole or in part, without the prior written consent of CredPal.</p>
          </div>

          <div class="signature-section">
            <p><b>IN WITNESS WHEREOF</b>, the parties hereto have executed this Affiliate Agreement as of the Effective Date.</p>
            <br>
            <p>Executed by the Authorized Signatory of CREDNET TECHNOLOGIES LIMITED:</p>
            <br>
            <p>________Tochukwu Udunwa_______________________</p>
            <br>
            <p>Name:</p>
            <br>
            <p>Executed by the within named Affiliate:</p>
            <br>
            <p>_______________________________</p>
            <br>
            <p>[Name of Wealth Partner]</p>
            <br>
            <p>Date:---------------------</p>
          </div>

          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Questions or Concerns
            </h2>
            <div class="leading-8">
              Please send all questions, comments, and feedback to us at
              <a
                href="mailto:affiliates@credpal.com"
                target="_blank"
                class="faq-contact-btn text-white p-5"
              >
                affiliates@credpal.com </a
              >.
            </div>
          </div>
        </div>
      </div>
    </section>

    <foot />
  </div>
</template>

<script>
export default {
  name: "Terms",
  components: {
    foot: require("./footer/Index").default,
  },
  data() {
    return {
      logo: require("@/assets/logo-blue.svg"),
    };
  },
  computed: {
    checkAuth() {
      return this.$store.state.session.loggedIn;
    },
  },
};
</script>

<style scoped>
header {
  background-color: #f3f5fc;
}
</style>
