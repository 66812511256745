<template>
	<div class="update-password-page">
		
		<form @submit.prevent="submit">
			
			<h1 class="text-4xl font-bold mb-1 leading-tight">Reset Your Password</h1>
			<div class="text-sm text-gray-600 mb-4">Enter a new password to proceed.</div>

			<template v-if="errorMessage">
				<div class="rounded-full bg-red-100 p-3 flex flex-row items-center mb-10">
					<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
						!
					</div>
					<div class="text-xs font-normal">{{ errorMessage }}</div>
				</div>
			</template>
			<template v-else>
				<div class="h-16"></div>
			</template>

			<form-group type="password" name="password" left-icon="lock-closed-outline" v-model="form.data.password.value" :form="form">
				Enter Password
			</form-group>

			<form-group type="password" name="password_confirmation" left-icon="lock-closed-outline" v-model="form.data.password_confirmation.value" :form="form">
				Repeat New Password
			</form-group>

			<div class="text-center">
				<button
					type="submit"
					class="btn btn-blue btn-md w-full"
					:disabled="form.loading || !form.validated || form.validating"
				>
					<span v-if="form.validating">Validating Token...</span>
					<span v-else-if="!form.validated">Invalid Token</span>
					<span v-else-if="form.loading">Submitting...</span>
					<span v-else>Set Password</span>
				</button>
			</div>

		</form>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Password was changed successfully
			</div>

			<router-link :to="{name: 'login'}" class="btn btn-blue btn-md">
				Login to your acccount!
			</router-link>
			
		</modal>

	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		data() {
			return {
				form: this.$options.basicForm([
					{name: 'password', value: '', rules: 'required|min:8|alphaNum'},
					{name: 'password_confirmation', value: '', rules: 'required|same:password'},
				], {
					validating: false,
					validated: false
				}),
				checkmark: require('@/assets/checkmark-base.svg'),
			}
		},
		computed: {
			errorMessage() {
				if (this.form.error.toString().match(/Error: Network Error/i)) {
					return 'Please check your internet connection';
				}

				return null;
			},
			sessionExpired() {
				return this.$route.query.expired;
			},
			token() {
				return this.$route.params.token;
			}
		},
		beforeMount() {
			this.validateToken();
		},
		methods: {
			...mapActions('session', ['updateUser']),

			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.loading = true;
				await this.$put({
					url: `${this.$baseurl}/affiliate/password/reset`,
					data: {
						token: this.token,
						password: this.form.data.password.value,
						password_confirmation: this.form.data.password_confirmation.value
					},
					headers: this.headers,
					success: () => {
						this.$refs.modal.open();
					},
					error: error => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});
				this.form.loading = false;
			},
			async validateToken() {
				this.form.validating = true;
				await this.$post({
					url: `${this.$baseurl}/affiliate/password/validate-token`,
					data: {
						token: this.token
					},
					headers: this.headers,
					success: () => {
						this.form.validated = true;
					},
					error: error => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});
				this.form.validating = false;
			}
		}
	}
</script>