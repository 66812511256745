<template>
  <div>
    <header class="flex header">
      <nav class="flex custom-container justify-between py-5">
        <div class="flex">
          <router-link
            class="btn w-56 -ml-12"
            style="color: #274FED;"
            :to="{ name: 'login' }"
          >
            <img :src="logo" alt="CredPal" class="" />
          </router-link>
        </div>
        <div
          class="flex w-full md:w-3/10 justify-end md:justify-between md:mr-12 md:mt-4"
        >
          <template v-if="!checkAuth">
            <div
              class="font-normal text-blue-500 cursor-pointer hidden md:block md:mr-4"
            >
              <router-link class="" :to="{ name: 'login' }">
                Sign In</router-link
              >
            </div>
            <div class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0">
              <router-link class="" :to="{ name: 'signup' }"
                >Get Started</router-link
              >
            </div>
          </template>
          <div
            v-else
            class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0 ml-auto"
          >
            <router-link class="" :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </div>
        </div>
      </nav>
    </header>
    <section class="section" style="padding-bottom: 50px;">
      <div class="custom-container">
        <div class="md:w-6/10 mx-auto">
          <h1 class="mb-6 text-4xl md:text-5xl font-black leading-tight">
            Privacy Policy
          </h1>
        </div>
        <div class="md:w-6/10 mx-auto">
          <div class="text-justify mb-10">
            We understand that the information you provide us is very important
            and valuable. We will like to therefore explain how we collect,
            share and use your information.
          </div>

          <div>
            <!-- first -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4">1.</span>COLLECTING AND STORING YOUR
                INFORMATION
              </h3>

              <div class="ml-8 my-4">
                <p class=" leading-8">We collect and store your information:</p>
                <ul class="list-disc ml-8 leading-8 leading-8">
                  <li>when you interact with us</li>
                  <li>
                    when you visit our websites or use our products, services
                    and mobile apps
                  </li>
                  <li>from other people and companies</li>
                </ul>
                <p class="my-4 leading-8">
                  We store your information in physical or electronic form on
                  our systems, our service providers’ systems or a third-party
                  cloud locally or overseas.
                </p>
                <p class=" leading-8">
                  We comply with the laws of Nigeria on privacy.
                </p>
              </div>
            </div>
            <!-- end of first -->

            <!-- sencond -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4">2.</span>USE OF YOUR INFORMATION
              </h3>

              <div class="ml-8 my-4">
                <p>We use your information:</p>
                <ul class="list-disc ml-8 leading-8 leading-8">
                  <li>
                    to provide products and services to you
                  </li>
                  <li>
                    to market products or services to you if you’ve given us
                    your consent to do so
                  </li>
                  <li>
                    to comply with laws, regulations and requirements, including
                    our internal policies
                  </li>
                  <li>
                    to help us to prevent financial crimes
                  </li>
                  <li>
                    to improve our products, services and marketing
                  </li>
                  <li>
                    for the other purposes set out in section A.
                  </li>
                </ul>
              </div>
            </div>
            <!-- end of second -->

            <!-- third -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4">3.</span>THOSE WE SHARE YOUR INFORMATION WITH
              </h3>

              <div class="ml-8 my-4">
                <p>We use your information:</p>
                <ul class="list-disc ml-8 leading-8 leading-8">
                  <li>
                    with other companies and third parties who help us to
                    provide services to you or who act for us
                  </li>
                  <li>
                    with third parties who you consent to us sharing your
                    information with
                  </li>
                  <li>
                    with local or overseas law enforcement agencies, industry
                    bodies, regulators or authorities
                  </li>
                  <li>
                    with the other third parties set out in section C.
                  </li>
                </ul>
                <p class=" leading-8">
                  We may share your information locally or overseas.
                </p>
              </div>
            </div>
            <!-- end of third -->

            <!-- start of fourth -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">4.</span>Accessing your
                information
              </h3>

              <div class="ml-8 my-4">
                <p class=" leading-8">
                  You can request access to the information we store about you,
                  we may charge a fee for this. You can also ask us to correct
                  or update your information.
                </p>

                <p class=" leading-8">
                  You have the option of choosing your marketing preferences.
                  You can contact us at any time if you don’t want us to send
                  direct marketing to you via certain channels, such as email or
                  post.
                </p>
              </div>
            </div>
            <!-- end of fourth -->

            <!-- start of fifth -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">5.</span>Your information may be
                used to
              </h3>

              <div class="ml-8 my-4">
                <ul class="list-disc ml-8  leading-8">
                  <li>
                    assess you for products and services and enable us to
                    provide and operate them
                  </li>
                  <li>
                    conduct verification or credit checks and reviews on you,
                    obtain or provide credit references or detect fraudulent
                    applications
                  </li>
                  <li>
                    manage our business, including our human resources, credit
                    and other risk management functions and to comply with our
                    internal policies
                  </li>
                  <li>
                    design and improve our products, services and marketing
                  </li>
                  <li>
                    provide you with marketing information as described below
                  </li>
                  <li>
                    provide personalized advertising (including by aggregating
                    your information with information of others)
                  </li>
                  <li>
                    exercise our rights under contracts with you, including
                    collecting any amounts due from you
                  </li>
                  <li>
                    comply with compulsory or voluntary requirements that we
                    have such as legal, tax, regulatory, sanctions or market
                    requirements in Nigeria and overseas
                  </li>
                  <li>
                    comply with requests made by different bodies or authorities
                    such as legal, regulatory, law enforcement, government and
                    tax authorities in Nigeria and overseas. Sometimes we may
                    have to comply with such requests and other times we may
                    choose to voluntarily comply
                  </li>
                  <li>
                    comply with our or company’s policies, procedures and other
                    commitments in the detection, investigation and prevention
                    of financial and other crimes. This may involve sharing your
                    information in Nigeria and overseas. This is important in
                    our wider fight against these crimes
                  </li>
                  <li>
                    allow any company that we transfer our business or assets
                    to, to evaluate our business and use your information after
                    any transfer
                  </li>
                  <li>
                    any other uses for which you have given consent
                  </li>
                  <li>
                    any other purpose relating to any of the purposes above.
                  </li>
                </ul>
                <p class=" leading-8">
                  This Privacy Policy will apply for as long as we store your
                  information. We’ll review this Privacy Policy once a year and
                  may make changes. We’ll publish a copy of the latest Privacy
                  Policy on our website and send you a copy on request.
                </p>
              </div>
            </div>
            <!-- end of fifth -->

            <!-- start of sixth -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">6.</span>We may collect
              </h3>

              <div class="ml-8 my-4">
                <ul class="list-disc ml-8  leading-8">
                  <li>
                    information (including sensitive information) that you give
                    to us such as your contact and identification details, tax
                    file number or information about your ethnicity or origin
                  </li>
                  <li>
                    biometric information such as your facial recognition
                    information
                  </li>
                  <li>
                    your geographic information and location information based
                    on your mobile or other electronic device
                  </li>
                  <li>information required under law.</li>
                </ul>
                <p class=" leading-8">
                  If you don’t give us information then we may be unable to
                  provide products or services.
                </p>

                <p class=" leading-8">
                  We may also collect information about you from third parties
                  such as
                </p>
                <ul class="list-disc ml-8 leading-8">
                  <li>
                    people who act for you or who you give consent to transfer
                    information to us
                  </li>
                  <li>
                    publicly available sources or agencies that collect
                    information which they use to create statistics or to verify
                    your identity
                  </li>

                  <li>
                    credit reference, debt collection, fraud prevention and
                    government agencies
                  </li>
                  <li>
                    those you deal with through our services.
                  </li>
                </ul>
                <p class=" leading-8">
                  We may generate information about you
                </p>
                <ul class="list-disc ml-8 leading-8">
                  <li>
                    by combining information that we have collected about you
                    and based on the analysis of your interactions with us
                    through the use of cookies and similar technology when you
                    access our website or apps. See our cookies policy on
                    www.credpal.com for more details.
                  </li>
                </ul>
              </div>
            </div>
            <!-- end of sixth -->

            <!-- start of seventh -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">7.</span>We may collect
              </h3>

              <div class="ml-8 my-4">
                <p class=" leading-8">
                  We may transfer and store your information overseas. Where
                  those countries don’t have the same level of protection for
                  personal information, we’ll take reasonable steps to ensure
                  overseas recipients provide the same level of protection.
                </p>
              </div>
            </div>
            <!-- end of seventh -->

            <!-- start of eight -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">8.</span>Protecting your stored
                information
              </h3>

              <div class="ml-8 my-4">
                <ul class="list-disc ml-8 leading-8">
                  <li>
                    While we store it, your information is protected by
                    physical, electronic and procedural safeguards and governed
                    by the laws of Nigeria and the Nigerian Privacy Principles.
                  </li>
                  <li>
                    Our third party service providers that receive your
                    information are required to comply with strict measures to
                    protect your information.
                  </li>
                  <li>
                    If you provide information about others
                  </li>
                  <li>
                    If you provide information to us about another person (such
                    as a joint account holder, trustee, beneficiary or
                    guarantor) you must make sure they know you have done this,
                    and agree to us
                  </li>
                  <li>
                    processing their information as set out in this Privacy
                    Policy. You also need to tell them how to find a copy of
                    this Privacy Policy.
                  </li>
                </ul>
              </div>
            </div>
            <!-- end of eighth -->

            <!-- start of ninth -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">9.</span>We share your information
                with
              </h3>

              <div class="ml-8 my-4">
                <ul class="list-disc ml-8 leading-8">
                  <li>
                    third parties who provide services to us or who you have
                    consented to us sharing your information with
                  </li>
                  <li>
                    other third parties we work with or parties we need to share
                    with for the purpose of acting on your instructions
                  </li>
                  <li>
                    any bodies or partnerships between law enforcement and the
                    financial sector or authorities, such as legal, regulatory,
                    law enforcement, government and tax authorities in Nigeria
                    or overseas or anyone acting for them
                  </li>
                  <li>
                    any person who you hold a joint account with, people who can
                    give instructions for you and anyone who may give security
                    for your loans
                  </li>
                  <li>
                    any third party who we may transfer our business or assets
                    to
                  </li>
                  <li>
                    partners and providers of reward, co-branding or loyalty
                    programs, charities or non-profit organisations
                  </li>
                  <li>
                    social media advertising partners (who can check if you hold
                    an account with them for advertising purposes and the
                    partner may then use your information to send our adverts to
                    you and advertise to people who have a similar profile to
                    you)
                  </li>
                  <p class=" leading-8">
                    We may share your anonymised information with other parties
                    not listed above. If we do this you won’t be identifiable
                    from this information.
                  </p>
                </ul>
              </div>
            </div>
            <!-- end of ninth -->

            <!-- start of tenth -->
            <div>
              <h3 class="font-bold text-2xl">
                <span class="mr-4 capitalize">10.</span>Direct Marketing
              </h3>

              <div class="ml-12 my-4">
                <p class=" leading-8">
                  This is when we use your personal information to send you
                  details about products, services and offers such as financial,
                  insurance or related products and services provided by us or
                  our co-branding partners, rewards, loyalty privileges or
                  charities. We’ll only do this where you’ve consented
                  (including your indication of no objection to us).
                </p>
                <p class=" leading-8">
                  We may use your contact details and information such as your
                  demographic information, products and services that you are
                  interested in, transaction behaviour, portfolio information,
                  location information, social media information, analytics and
                  formation from third parties when we market you.
                </p>
                <p class=" leading-8">
                  You can tell us anytime if you don’t want to receive marketing
                  from us by clicking our “unsubscribe” links in our emails,
                  contacting us (it may take a short time to update our records
                  to reflect any change), or updating your preferences on social
                  media platforms.
                </p>
                <p class=" leading-8">
                  We’ll need to get your separate consent to share your
                  information with others for them to market their products and
                  services to you.
                </p>
              </div>
            </div>
            <!-- end of tenth -->

            <!-- start of eleventh -->
            <div>
              <h3 class="font-bold text-2xl">
                Your rights
              </h3>

              <div class="ml-8 my-4">
                <p class=" leading-8">
                  You can request access to your information, correct
                  information that we hold about you and give feedback. We may
                  be unable to provide you with access to some information — if
                  so, we’ll explain why not unless it’s not lawful to tell you.
                  You can also ask us to explain our data policies and
                  practices.
                </p>
              </div>
            </div>
            <!-- end of eleventh -->

            <!-- start of twelve -->
            <div>
              <h3 class="font-bold text-2xl">
                Other information
              </h3>

              <div class="ml-8 my-4">
                <p class=" leading-8">
                  We collect the following information because we are required
                  or authorised by or under the Nigerian law:
                </p>
                <ul class="list-disc ml-8 leading-8 leading-8">
                  <li>
                    your identification information under the Central Bank of
                    Nigeria (Anti-Money Laundering/Combating the Financing of
                    Terrorism for Banks and Other Financial Institutions in
                    Nigeria) Regulation 2013 and The Nigeria Data Protection
                    Regulation (NDPR)
                  </li>
                  <li>
                    your credit or financial information under the Central Bank
                    of Nigeria Act 2007 (CBN)
                  </li>
                </ul>
                <p class=" leading-8">
                  We may ask a credit reporting body to assist in verifying your
                  identity by providing us with an assessment of whether the
                  information we hold about you matches their records. The
                  credit reporting body may use this information and information
                  held by the body of other individuals for the purpose of
                  preparing and providing this assessment to us.
                </p>
                <p class=" leading-8">
                  The Nigeria Data Protection Regulation (NDPR) contains certain
                  exemptions in relation to certain acts undertaken in relation
                  to employee records and related bodies corporate. Where
                  appropriate we make use of relevant exemptions in the Act.
                </p>
                <p class=" leading-8">
                  This Privacy Policy applies to all CredPal’s product operating
                  in Nigeria
                </p>
              </div>
            </div>
            <!-- end of twelve -->

            <!-- start of twelve -->
            <div>
              <h3 class="font-bold text-2xl">
                Making a Complaint
              </h3>

              <div class="my-4">
                <p class=" leading-8">
                  If you wish to make a complaint about the way we have handled
                  your personal information, you can contact us via email at
                  cardservices@credpal.com
                </p>
                <p class=" leading-8">
                  We will attempt to respond to any complaints, access or
                  correction requests as soon as possible, and no later than 30
                  days once we’ve verified your identity. However, where a
                  matter is complex, we may need to ask you for an extension to
                  this period and give you the reasons why we need to have the
                  period extended. If you do not consent to the extension sought
                  by us, we may not be able to resolve your complaint, access or
                  correction request.
                </p>
              </div>
            </div>
            <!-- end of twelve -->
          </div>
        </div>
      </div>
    </section>
    <foot />
  </div>
</template>

<script>
export default {
  name: "Privacy",
  components: {
    foot: require("./footer/Index").default,
  },
  data() {
    return {
      logo: require("@/assets/logo-blue.svg"),
    };
  },
  computed: {
    checkAuth() {
      return this.$store.state.session.loggedIn;
    },
  },
};
</script>
<style scoped>
header {
  background-color: #f3f5fc;
}
</style>
